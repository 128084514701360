import { post } from '@helpers/fetch-wrapper'
import React, { useState } from 'react'
import useSWRImmutable from 'swr/immutable'

import useTransactionHistory from '@features/coins/hooks/useTransactionHistory'

import Button from '@components/button/Button'
import Drawer from '@components/drawer/Drawer'

import { useAppSelector } from '@hooks/redux'

import { INITIALIZE_URL } from '@constants/url'

import { useCoinsActions } from '@store/coins/slice'

import CoinsDrawerTitle from '../coins-drawer-title'
import CoinsEmptyState from '../coins-empty-state/CoinsEmptyState'
import TransactionDetailModal from '../transaction-detail-modal'
import TransactionHistoryCard from '../transaction-history-card/TransactionHistoryCard'

const TransactionHistoryDrawer = () => {
  const { openTransactionHistoryDrawer } = useAppSelector(
    (state) => state.coins
  )
  const [openDetailModa, setOpenDetailModal] = useState<boolean>(false)
  const {
    transactionHistoryData,
    setTransactionHistorySize,
    transactionHistorySize,
    totalPage,
  } = useTransactionHistory({ shouldFetch: openTransactionHistoryDrawer })
  const { setTransactionHistoryDrawer } = useCoinsActions()

  const { data: initializeData } = useSWRImmutable<{
    featureToggle: {
      canBoost: boolean
      canUseCoins: boolean
    }
  }>(INITIALIZE_URL, async (url: string) => {
    try {
      const response = await post(url)
      return response.data
    } catch (e) {
      return undefined
    }
  })

  if (!initializeData?.featureToggle.canUseCoins) return null

  return (
    <>
      <Drawer
        title={
          <CoinsDrawerTitle
            title="Riwayat transaksi"
            onClose={() => setTransactionHistoryDrawer(false)}
          />
        }
        isOpen={openTransactionHistoryDrawer}
        onClose={() => setTransactionHistoryDrawer(false)}
        hideCloseButton
      >
        <div className="flex flex-col gap-y-4">
          {transactionHistoryData.length === 0 ? (
            <CoinsEmptyState />
          ) : (
            transactionHistoryData.map((transactionData) => (
              <TransactionHistoryCard
                key={transactionData.id}
                transactionType={transactionData.transactionType}
                title={transactionData.title}
                transactionStatus={transactionData.status}
                amount={transactionData.amount}
                setOpenDetailModal={setOpenDetailModal}
                transactionId={transactionData.id}
                transactionDate={transactionData.createdAt}
              />
            ))
          )}
          {totalPage &&
          totalPage > 1 &&
          transactionHistorySize !== totalPage ? (
            <Button
              onClick={() => setTransactionHistorySize((s) => s + 1)}
              type="button"
              appearance="outline-primary"
            >
              Tampilkan lainnya
            </Button>
          ) : null}
        </div>
      </Drawer>
      <TransactionDetailModal
        open={openDetailModa}
        onClose={() => setOpenDetailModal(false)}
      />
    </>
  )
}

export default TransactionHistoryDrawer
