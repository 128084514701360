import { get } from '@helpers/fetch-wrapper'
import { format } from 'date-fns'
import Image from 'next/image'
import React from 'react'
import useSWRInfinite from 'swr/infinite'

import Modal from '@components/modal'
import Paragraph from '@components/typography/paragraph'

import { useAppSelector } from '@hooks/redux'

import { COINS_EXPIRED_LIST_URL } from '@constants/url'

import { useCoinsActions } from '@store/coins/slice'

import { GetExpiredCoinsListAPIResponse } from '@custom-types/responses'

const coinsExpiredListFetcher = ([url]: [string]) => {
  return get(`${url}`).then((res) => res.data)
}

const CoinsExpiredModal = () => {
  const open = useAppSelector((state) => state.coins.expiredModal.open)
  const coinsActions = useCoinsActions()

  const uniqueTimestampRef = React.useRef(Date.now())

  const {
    data: coinsExpiredListData,
    isLoading,
    isValidating,
    setSize,
  } = useSWRInfinite<GetExpiredCoinsListAPIResponse>(
    (pageIndex, previousPagedata: GetExpiredCoinsListAPIResponse | null) => {
      if (previousPagedata && previousPagedata.records.length <= 0) return null
      if (
        previousPagedata &&
        pageIndex + 1 > previousPagedata.metaData.totalCount
      )
        return null

      return [
        COINS_EXPIRED_LIST_URL({ page: pageIndex + 1 }),
        uniqueTimestampRef.current,
      ]
    },
    coinsExpiredListFetcher,
    {
      shouldRetryOnError: false,
      revalidateFirstPage: false,
      revalidateOnMount: true,
    }
  )

  const totalPage = React.useMemo(
    () =>
      coinsExpiredListData && coinsExpiredListData.length > 0
        ? coinsExpiredListData[0].metaData.totalCount
        : 0,
    [coinsExpiredListData]
  )
  const currPage = React.useMemo(
    () =>
      coinsExpiredListData && coinsExpiredListData.length > 0
        ? coinsExpiredListData[coinsExpiredListData.length - 1].metaData.page
        : 0,
    [coinsExpiredListData]
  )

  const lastItemRef = React.useRef<HTMLDivElement>(null)

  const handleClose = () => {
    coinsActions.setCoinsExpiredModalState({ open: false })
  }

  React.useEffect(() => {
    if (!lastItemRef.current || !coinsExpiredListData) return
    const obs = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && currPage < totalPage && !isValidating) {
        setSize((n) => n + 1)
      }
    })

    obs.observe(lastItemRef.current)
    return () => {
      obs.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currPage, isValidating, totalPage, coinsExpiredListData])

  return (
    <Modal
      isHeaderHidden
      open={open}
      onClose={handleClose}
      className="w-[25rem]"
    >
      <div className="flex items-center justify-between py-4">
        <Paragraph weight="semibold" className="text-xl">
          Koin yang akan kedaluwarsa
        </Paragraph>
        <button type="button" onClick={handleClose} className="h-5 w-5">
          <Image
            alt="icon"
            src="/assets/icons/ic_close.svg"
            width={24}
            height={24}
          />
        </button>
      </div>
      <div className="flex items-start gap-x-2 rounded-lg bg-[#F4F6FB] p-2">
        <Image
          alt="icon"
          src="/assets/icons/ic_price_list.svg"
          width={30}
          height={30}
        />
        <Paragraph className="text-sm">
          Daftar koin yang akan kedaluwarsa dalam 30 hari ke depan
        </Paragraph>
      </div>
      <div className="max-h-[300px]">
        {!coinsExpiredListData || isLoading ? (
          <Image
            className="animate-spin"
            alt="animate-logo"
            src="/assets/images/img_spinner_blue.png"
            width={40}
            height={40}
          />
        ) : (
          <>
            <div className="py-4">
              {coinsExpiredListData
                .flatMap((v) => v.records)
                .map((v, idx, arr) => (
                  <div
                    key={v.id}
                    className="mt-4 flex items-center justify-between rounded-lg border-2 p-4"
                    {...(idx === arr.length - 1 && {
                      ref: lastItemRef,
                    })}
                  >
                    <Paragraph>
                      {format(new Date(v.expiryAt), 'dd MMMM yyyy')}
                    </Paragraph>
                    <div className="flex gap-x-2">
                      <Image
                        alt="icon"
                        src="/assets/icons/ic_coin.svg"
                        width={20}
                        height={20}
                      />
                      <Paragraph weight="semibold">{v.amount}</Paragraph>
                    </div>
                  </div>
                ))}
            </div>
            {isValidating && (
              <Image
                className="animate-spin"
                alt="animate-logo"
                src="/assets/images/img_spinner_blue.png"
                width={40}
                height={40}
              />
            )}
          </>
        )}
      </div>
    </Modal>
  )
}

export default CoinsExpiredModal
