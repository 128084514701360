/* eslint-disable no-use-before-define */
import useSWR from 'swr'

import { TRANSACTION_DETAIL_URL } from '@constants/url'

import { GetTransactionDetailAPIResponse } from '../types'

export const useTransactionDetail = (transactionId: string) => {
  const { data, mutate, isLoading } = useSWR<GetTransactionDetailAPIResponse>(
    transactionId ? TRANSACTION_DETAIL_URL(transactionId) : null
  )

  return {
    transactionDetailData: data,
    mutateTransactionDetail: mutate,
    transactionDetailIsLoading: isLoading,
  }
}

export default useTransactionDetail
