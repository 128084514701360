/* eslint-disable arrow-body-style */
import { format } from 'date-fns'
import locale from 'date-fns/locale/id'
import Image from 'next/image'
import React from 'react'
import NumberFormat from 'react-number-format'

import useTransactionDetail from '@features/coins/hooks/useTransactionDetail'

import Paragraph from '@components/typography/paragraph'

import { useAppSelector } from '@hooks/redux'

import TransactionStatusBadge from '../transaction-status-badge'

const TransactionDetailSummary = () => {
  const { selectedTransactionId } = useAppSelector((state) => state.coins)

  const { transactionDetailData } = useTransactionDetail(selectedTransactionId)

  if (!transactionDetailData) return null

  return (
    <div className="border-b-2 p-4">
      <div className="flex flex-col items-center justify-center">
        <Image
          alt="icon"
          src={
            transactionDetailData.transactionType === 'Credit'
              ? '/assets/icons/ic_transaction_credit.svg'
              : '/assets/icons/ic_transaction_debit.svg'
          }
          width={50}
          height={50}
        />
        <Paragraph weight="semibold" className="mt-2">
          {transactionDetailData.title}
        </Paragraph>
      </div>

      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center gap-x-1">
          <p className="text-[12px] font-[400] text-primary-gray">
            {format(
              new Date(transactionDetailData.createdAt),
              'dd MMMM yyyy - HH:mm',
              {
                locale,
              }
            )}
          </p>
        </div>
        <TransactionStatusBadge status={transactionDetailData.status} />
      </div>

      <div className="mt-4 flex items-center justify-between">
        <Paragraph accent="secondary" className="text-sm font-thin">
          {transactionDetailData.transactionType === 'Credit'
            ? 'Atma Coins didapat'
            : 'Atma Coins digunakan'}
        </Paragraph>
        <div className="flex gap-x-2">
          <Image
            alt="icon"
            src="/assets/icons/ic_coin.svg"
            width={20}
            height={20}
          />
          <Paragraph weight="semibold">
            <NumberFormat
              fixedDecimalScale={false}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={0}
              value={transactionDetailData.amount}
              displayType="text"
            />
          </Paragraph>
        </div>
      </div>

      {transactionDetailData.status !== 'Failed' &&
        transactionDetailData.transactionType !== 'Debit' && (
          <div className="mt-4 flex items-center justify-between">
            <Paragraph accent="secondary" className="text-sm font-thin">
              Akan kedaluwarsa pada
            </Paragraph>
            <Paragraph className="text-sm font-thin">
              {format(
                new Date(transactionDetailData.expiryAt),
                'dd MMMM yyyy',
                {
                  locale,
                }
              )}
            </Paragraph>
          </div>
        )}
    </div>
  )
}

export default TransactionDetailSummary
