import Image from 'next/image'
import React from 'react'

import useTransactionDetail from '@features/coins/hooks/useTransactionDetail'

import Button from '@components/button'
import Modal from '@components/modal'
import H2 from '@components/typography/heading-two'

import { useAppSelector } from '@hooks/redux'

import { ATMA_CS_PHONE_NUMBER } from '@constants/phone-number'
import shareToWhatsApp from '@constants/whatsapp-url'

import TransactionDetailInfo from '../transaction-detail-info/TransactionDetailInfo'
import TransactionDetailSummary from '../transaction-detail-summary'

type Props = {
  open: boolean
  onClose: () => void
}

const TransactionDetailModal = ({ open, onClose }: Props) => {
  const { selectedTransactionId } = useAppSelector((state) => state.coins)

  const { transactionDetailData } = useTransactionDetail(selectedTransactionId)

  return (
    <Modal isHeaderHidden open={open} onClose={onClose} className="w-[30rem]">
      <div className="flex items-center justify-between pt-4">
        <H2>Detail transaksi</H2>
        <button type="button" onClick={onClose} className="h-5 w-5">
          <Image
            alt="icon"
            src="/assets/icons/ic_close.svg"
            width={24}
            height={24}
          />
        </button>
      </div>
      <div className="mt-4 rounded-xl border-2">
        <TransactionDetailSummary />
        <TransactionDetailInfo />
      </div>

      {transactionDetailData?.status === 'Pending' && (
        <Button
          block
          appearance="primary"
          type="button"
          onClick={() => {
            if (transactionDetailData.paymentTransaction?.paymentURL) {
              window.open(
                transactionDetailData.paymentTransaction.paymentURL,
                '_blank'
              )
            }
          }}
          className="mt-[16px]"
        >
          Lanjutkan pembayaran
        </Button>
      )}
      <a
        type="button"
        href={shareToWhatsApp('+62', ATMA_CS_PHONE_NUMBER)}
        target="_blank"
        rel="noreferrer"
        className="mt-4 flex w-full items-center justify-center space-x-2 rounded-xl border-2 border-primary-blue p-4 font-semibold text-primary-blue hover:bg-primary-blue hover:text-white"
      >
        <Image
          alt="icon"
          src="/assets/icons/ic_whatsapp_green.svg"
          width={20}
          height={20}
        />
        <p>Ada pertanyaan? Tanya CS</p>
      </a>
    </Modal>
  )
}

export default TransactionDetailModal
