import useSWRInfinite from 'swr/infinite'

import { TRANSACTION_HISTORY_URL } from '@constants/url'

import { GetTransactionHistoryAPIResponse } from '../types'

export const useTransactionHistory = (
  {
    shouldFetch,
  }: {
    shouldFetch?: boolean
  } = { shouldFetch: true }
) => {
  const { data, mutate, isLoading, setSize, size } =
    useSWRInfinite<GetTransactionHistoryAPIResponse>(
      (pageIndex, previousPageData) => {
        if (!shouldFetch) return null
        const transactionHistoryQuery: { page: number; lastId?: string } = {
          page: pageIndex + 1,
        }

        if (previousPageData) {
          transactionHistoryQuery.lastId =
            previousPageData.records[previousPageData.records.length - 1].id
        }

        return TRANSACTION_HISTORY_URL(transactionHistoryQuery)
      },
      {
        revalidateIfStale: true,
      }
    )

  const transactionHistoryData = data
    ? data?.reduce(
        (prev: GetTransactionHistoryAPIResponse['records'], curr) => [
          ...prev,
          ...curr.records,
        ],
        []
      )
    : []

  return {
    transactionHistoryData,
    mutateTransactionHistory: mutate,
    isLoading,
    setTransactionHistorySize: setSize,
    transactionHistorySize: size,
    totalPage: data && data[0].metaData.totalCount,
  }
}

export default useTransactionHistory
