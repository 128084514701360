import React from 'react'

import CloseIcon from '@common/icons/CloseIcon'

type Props = {
  title: string
  hideCloseButton?: boolean
  onClose: () => void
}

const CoinsDrawerTitle = ({
  title,
  hideCloseButton = false,
  onClose,
}: Props) => (
  <div className="flex w-full items-center justify-between p-4">
    <header className="text-xl font-medium">{title}</header>
    {!hideCloseButton && (
      <button type="button" className="w-fit self-end" onClick={onClose}>
        <CloseIcon />
      </button>
    )}
  </div>
)

export default CoinsDrawerTitle
